import React, { Component } from 'react'
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from "../components/form"
import { graphql } from "gatsby"
import styled from 'styled-components';
// import Anime from 'react-anime';
const colours = ['green', 'tan', 'blue-light', 'green'];

const OfficeHeading = styled.h2`
  @media screen and (min-width:1024px) {
    font-size:2.75em;
  }
`;


export default class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formOpen: false
    }

    this.toggleForm = this.toggleForm.bind(this);
  }

  toggleForm() {
    return this.setState(()=>({
      ...this.state,
      formOpen: !this.state.formOpen
    }))
  }

  render() {
    const { acf, yoast_meta } = this.props.data.page;

    return (
      <Layout>
        <SEO yoast={yoast_meta} />

        <Form
          acf={acf.contact_form}
          isOpen={this.state.formOpen}
          toggleForm={this.toggleForm}
        />

        <section className="md:py-20 md:pt-24 pt-12 pb-16 bg-white text-center">
          <div className="o-wrapper">
            <h1 className="u-h1 fade-in-up">{acf.hero.heading}</h1>
          </div>
        </section>

        <section className="md:py-20 py-12 bg-tertiary">
          <div className="o-wrapper">
            <div className="grid md:grid-cols-2 gap-6">
              {acf.two_col_boxes.boxes.map(({ heading, content, buttons }, i)=>
                <div className="bg-white xl:px-16 xl:py-20 sm:px-12 sm:py-16 px-8 py-10" key={`contact-tc-box-${i}`}>
                  <h3 className="u-h3 mb-4">{heading}</h3>
                  <p className="mb-10 leading-normal">{content}</p>
                  {buttons.map((button, cnt) =>
                    <div className="mt-4" key={'contact-tc-box-btn-'+button.button.url+cnt}>
                      {button.button.url.includes('#form') &&
                        <button
                          onClick={this.toggleForm}
                          className="c-btn block w-full lg:w-auto lg:inline-block bg-black text-white hover:bg-green-light hover:text-black transition duration-150 outline-none"
                          to={button.button.url}
                        >
                          {button.button.title}
                        </button>
                      }

                      {!button.button.url.includes('#form') &&
                        <Link
                          className="c-btn block w-full lg:w-auto lg:inline-block bg-black text-white hover:bg-green-light hover:text-black transition duration-150"
                          target={button.button._target}
                          to={button.button.url}
                        >
                          {button.button.title}
                        </Link>
                      }
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="text-center mt-12 font-medium text-20px apply-c-btn--underline mb-4 md:mb-0" dangerouslySetInnerHTML={{ __html: acf.two_col_boxes.disclaimer }} />

          </div>
        </section>

        <section className="md:my-24 my-16">
          <div className="o-wrapper py-1">
            <div className="flex flex-wrap -ml-20">
              <div className="pl-20 lg:w-3/12 mb-12">
                <OfficeHeading className="u-h2 mb-5">{ acf.contact_details.heading }</OfficeHeading>
                <address className="not-italic" dangerouslySetInnerHTML={{__html:this.props.data.address.options.global.formatted_address}} />
              </div>
              <div className="pl-20 lg:w-9/12 w-full">
                <div className="grid md:grid-cols-2 gap-6">
                  {acf.contact_details.contact_methods.map((box, i) =>
                    <div className={`py-8 sm:py-12 px-6 sm:px-10 border-b-4 border-${colours[i]}`} style={{backgroundColor:'#FAFAFA'}} key={`contact-method-${box.heading}`}>
                      <h3 className="u-h4 sm:mb-8 mb-6">{box.heading}</h3>

                      {box.methods.map(({heading, content}, i) =>
                        <div className="mt-5" key={`box-method-${i}`}>
                          <h4 className="font-semibold leading-snug">{heading}</h4>
                          <div>
                            <a
                              href={(content.includes('@') ? 'mailto:' : 'tel:')+content}
                              className="hover:underline"
                            >
                              {content}
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="md:my-28 my-20">
          <div className="o-wrapper">
            <div className="bg-blue-light px-6 py-10 sm:p-16 lg:p-20">
              <div className="flex flex-wrap items-center justify-center text-center md:text-left md:justify-start -ml-10">

                <div className="md:w-7/12 pl-10">
                  <h3 className="u-h3 mb-4">{acf.cta.heading}</h3>
                  <h5 dangerouslySetInnerHTML={{__html:acf.cta.content}} />

                </div>

                <div className="md:w-5/12 pl-10 flex flex-col justify-end items-end mt-8 md:mt-0">
                  <Link className="c-btn bg-black text-white hover:bg-tertiary hover:text-black transition duration-150" to={acf.cta.button.url} target={acf.cta.button.target}>{acf.cta.button.title}</Link>
                </div>

              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}


export const query = graphql`
query {

  address: wordpressAcfOptions {
    options {
      global {
        formatted_address
      }
    }
  }

  page: wordpressPage(template:{eq:"contact.php"}) {
    id
    ...Yoast
    acf {
      hero {
        heading
      }
      contact_form {
        heading
        content
        confirmation {
          heading
          content
        }
      }
      two_col_boxes {
        boxes {
          heading
          content
          buttons {
            button {
              title
              url
              target
            }
          }
        }
        disclaimer
      }
      contact_details {
        heading
        contact_methods {
          heading
          methods {
            heading
            content
          }
        }
      }
      cta {
        heading
        content
        button {
          title
          url
          target
        }
      }
    }
  }
}
`
